import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Blog from "../epics/blog/ArticleList";

const TagPage = ({ pageContext, data }) => {
  const tag = pageContext.tag;
  return (
    <Layout>
      <Blog
        tag={tag}
        data={data}
        blogDetails={data.markdownRemark.frontmatter}
      />
    </Layout>
  );
};

TagPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default TagPage;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        title
        description
      }
    }

    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___datePublished], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            category
            description
            datePublished(formatString: "MMMM Do, YYYY")
            image {
              childImageSharp {
                fluid(maxHeight: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          fields {
            slug
            readingTime {
              minutes
            }
          }
        }
      }
    }
  }
`;
